import {
  SET_ACTIVE_MARKER,
  SET_BROWSE_DESCIPLINES,
  SET_BROWSE_DISPLAY,
  SET_BROWSE_FUNDERS,
  SET_DESCIPLINES,
  SET_DESCIPLINE_DETAIL,
  SET_DESCIPLINE_DETAILS_OPEN,
  SET_FUNDERS,
  SET_FUNDERS_DETAILS,
  SET_FUNDER_PROJECT_DETAIL,
  SET_IS_SEARCHED,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION_TAB,
  SET_PROJECT_DETAILS_OPEN,
  SET_SEARCHED_ORGANIZATIONS,
  SET_SEARCH_DISPLAY,
  SET_SELECTED_DESCIPLINE,
  SET_SELECTED_FUNDER,
  SET_SELECTED_FUNDER_PROJECT,
  SET_SELECTED_ORGANIZATION,
  SET_VIEWER_REF,
  SET_X,
} from "../constants/data";
import { initialState } from "../store/store";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case SET_IS_SEARCHED:
      return {
        ...state,
        isSearched: action.payload,
      };
    case SET_SEARCHED_ORGANIZATIONS:
      return {
        ...state,
        searchedOrganizations: action.payload,
      };
    case SET_SEARCH_DISPLAY:
      return {
        ...state,
        displaySearch: action.payload,
      };
    case SET_BROWSE_DISPLAY:
      return {
        ...state,
        displaySearch: false,
        displayBrowse: action.payload,
      };
    case SET_DESCIPLINES:
      return {
        ...state,
        desciplines: action.payload,
      };
    case SET_DESCIPLINE_DETAIL:
      return {
        ...state,
        isDesciplineDetail: action.payload,
      };
    case SET_SELECTED_DESCIPLINE:
      return {
        ...state,
        selectedDescipline: action.payload,
      };
    case SET_BROWSE_FUNDERS:
      return {
        ...state,
        isBrowseFunders: action.payload,
      };
    case SET_FUNDERS:
      return {
        ...state,
        funders: action.payload,
      };
    case SET_FUNDERS_DETAILS:
      return {
        ...state,
        isFundersDetails: action.payload,
      };
    case SET_SELECTED_FUNDER:
      return {
        ...state,
        selectedFunder: action.payload,
      };
    case SET_SELECTED_FUNDER_PROJECT:
      return {
        ...state,
        selectedFunderProject: action.payload,
      };
    case SET_FUNDER_PROJECT_DETAIL:
      return {
        ...state,
        isFundersProjectDetails: action.payload,
      };
    case SET_BROWSE_DESCIPLINES:
      return {
        ...state,
        isBrowserDesciplines: action.payload,
      };
    case SET_ORGANIZATION_TAB:
      return {
        ...state,
        currentOrganizationTab: action.payload,
      };
    case SET_PROJECT_DETAILS_OPEN:
      let projectId = action.payload;
      const updatedSelectedOrganizationProject = state.selectedOrganization;
      for (let project of updatedSelectedOrganizationProject.tab_projects) {
        if (project.project_id === parseInt(projectId)) {

          const ab = document.getElementsByClassName('accordion-button')
          console.log(ab);
          if(!ab[0].classList.contains('collapsed')){
            project.isOpen = false;
          }else{
            project.isOpen = true;
          }

          // project.isOpen = !project.isOpen;
        }
      }
      return {
        ...state,
        selectedOrganization: updatedSelectedOrganizationProject,
      };
    case SET_DESCIPLINE_DETAILS_OPEN:
      let desciplineId = action.payload;
      const updatedSelectedOrganizationDescipline = state.selectedOrganization;
      for (let descipline of updatedSelectedOrganizationDescipline.tab_disciplines) {
        if (descipline.discipline_id === parseInt(desciplineId)) {
          descipline.isOpen = !descipline.isOpen;
        }
      }
      return {
        ...state,
        selectedOrganization: updatedSelectedOrganizationDescipline,
      };
    case SET_ACTIVE_MARKER:
      return {
        ...state,
        activeMarker: action.payload,
      };
    case SET_VIEWER_REF:
      return {
        ...state,
        viewer: action.payload,
      };
    default:
      return null;
  }
};
