import {
	getDesciplineByIdUrl,
	getFunderByIdUrl,
	getFunderProjectByIdUrl,
	getSearchedOrganizationUrl,
	getSelectedOrganizationUrl,
	GET_ALL_DESCIPLINES,
	GET_ALL_FUNDERS,
	GET_ALL_ORGANIZATIONS,
	GET_METHOD,
} from "../constants/data";

export const getOrganizationById = (id) => {
	return fetch(getSelectedOrganizationUrl(id), {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json[0] };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getAllOrganizations = () => {
	return fetch(GET_ALL_ORGANIZATIONS, {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const searchOrganization = (value) => {
	return fetch(getSearchedOrganizationUrl(value), {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getAllDesciplines = () => {
	return fetch(GET_ALL_DESCIPLINES, {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getDesciplineById = (id) => {
	return fetch(getDesciplineByIdUrl(id), {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getAllFunders = (id) => {
	// debugger;
	return fetch(GET_ALL_FUNDERS, {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getFunderById = (id) => {
	return fetch(getFunderByIdUrl(id), {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
export const getFunderProjectById = (id) => {
	return fetch(getFunderProjectByIdUrl(id), {
		method: GET_METHOD,
	})
		.then(function (response) {
			return response.json();
		})
		.then((json) => {
			return { isSuccess: true, payload: json };
		})
		.catch((error) => {
			return { isSuccess: false, payload: error.messsage };
		});
};
