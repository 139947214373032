export const GET_METHOD = "GET";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";
export const SET_IS_SEARCHED = "SET_IS_SEARCHED";
export const SET_SEARCHED_ORGANIZATIONS = "SET_SEARCHED_ORGANIZATIONS";
export const SET_SEARCH_DISPLAY = "SET_SEARCH_DISPLAY";
export const SET_BROWSE_DISPLAY = "SET_BROWSE_DISPLAY";
export const SET_DESCIPLINES = "SET_DESCIPLINES";
export const SET_DESCIPLINE_DETAIL = "SET_DESCIPLINE_DETAIL";
export const SET_SELECTED_DESCIPLINE = "SET_SELECTED_DESCIPLINE";
export const SET_BROWSE_FUNDERS = "SET_BROWSE_FUNDERS";
export const SET_FUNDERS = "SET_FUNDERS";
export const SET_FUNDERS_DETAILS = "SET_FUNDERS_DETAILS";
export const SET_SELECTED_FUNDER = "SET_SELECTED_FUNDER";
export const SET_SELECTED_FUNDER_PROJECT = "SET_SELECTED_FUNDER_PROJECT";
export const SET_FUNDER_PROJECT_DETAIL = "SET_FUNDER_PROJECT_DETAIL";
export const SET_BROWSE_DESCIPLINES = "SET_BROWSE_DESCIPLINES";
export const SET_ORGANIZATION_TAB = "SET_ORGANIZATION_TAB";
export const SET_PROJECT_DETAILS_OPEN = "SET_PROJECT_DETAILS_OPEN";
export const SET_DESCIPLINE_DETAILS_OPEN = "SET_DESCIPLINE_DETAILS_OPEN";
export const SET_ACTIVE_MARKER = "SET_ACTIVE_MARKER";
export const SET_VIEWER_REF = "SET_VIEWER_REF";

export const GET_ALL_ORGANIZATIONS =
  "https://dev.wp-experten.org/researchearth/wp-json/organizations/all/v1/organizations";
export const GET_ALL_DESCIPLINES =
  "https://dev.wp-experten.org/researchearth/wp-json/disciplines/all/v1/disciplines";
export const GET_ALL_FUNDERS =
  "https://dev.wp-experten.org/researchearth/wp-json/funders/all/v1/funders";

export const getSelectedOrganizationUrl = (id) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/organizations/all/v1/organizations?id=${id}`;
};
export const getSearchedOrganizationUrl = (value) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/search/organizations/v1/search?s=${value}`;
};
export const getDesciplineByIdUrl = (id) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/disciplines/all/v1/disciplines?id=${id}`;
};

export const getFunderByIdUrl = (id) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/funders/all/v1/funders?id=${id}`;
};
export const getFunderProjectByIdUrl = (id) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/projects/all/v1/projects?id=${id}`;
};
export const getSelectedOrganizationProjectUrl = (id) => {
  return `https://dev.wp-experten.org/researchearth/wp-json/projects/all/v1/projects?id=${id}`;
};
