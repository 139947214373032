import React, { useContext } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './Search.css';
import Search from './Search';
import Browse from './Browse';
import OrganizationCard from './OrganizationCard';
import './Search.css';
import InfoBox from './InfoBox';

export default function SideTab(props) {
    const [toogle, setToogle] = React.useState(true);
    return (
        <div className="sidetab-container">
            <div className="d-block d-md-none toogle-card-container">
                <hr
                    class="close-desciplines-tab toogle-card"
                    onClick={() => setToogle(!toogle)}
                />
            </div>
            {toogle ? (
                <div className="searchBox">
                    <InfoBox />
                    <Search
                        setActiveMarker={props.setActiveMarker}
                        activeMarker={props.activeMarker}
                        setDefaultZoom={props.setDefaultZoom}
                        cb={props.cb}
                        removeSelectedEntityOnPintch={props.removeSelectedEntityOnPintch}
                        globeDuration={props.globeDuration}
                        setIsCameraFlyDone={props.setIsCameraFlyDone}
                    />
                    <Browse
                        setActiveMarker={props.setActiveMarker}
                        setDefaultZoom={props.setDefaultZoom}
                        cb={props.cb}
                        removeSelectedEntityOnPintch={props.removeSelectedEntityOnPintch}
                        globeDuration={props.globeDuration}
                        setIsCameraFlyDone={props.setIsCameraFlyDone}
                    />
                    <OrganizationCard
                        setActiveMarker={props.setActiveMarker}
                        setDefaultZoom={props.setDefaultZoom}
                        cb={props.cb}
                        removeSelectedEntityOnPintch={props.removeSelectedEntityOnPintch}
                        globeDuration={props.globeDuration}
                        setIsCameraFlyDone={props.setIsCameraFlyDone}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
