import React from 'react';
import './Search.css';
import ImpressionModal from "./impressionModal";
import DatenschutzModal from "./datenschutzModal";

import $ from 'jquery';

import 'bootstrap';


export default function ControlComponent(props) {
    const openImpressionModal = () => {

        $("#datenschutzModal").modal("hide");
        $("#impressionModal").modal("show");
    };
    const openDatenschutzModal = () => {
        $("#impressionModal").modal("hide");
        $("#datenschutzModal").modal("show");
    };
    const modalClose = () => {
        $("#impressionModal").modal("hide");
    };
    const modalDatenClose = () => {
        $("#datenschutzModal").modal("hide");
    };
    const [show, setShow] = React.useState(false);
    const handleCloseImpression = () => setShow(false);
    const handleShowImpression = () => setShow(true);

    const [showDatenschutz, setShowDatenschutz] = React.useState(false);
    const handleShowDatenschutz = () => setShowDatenschutz(true);
    const handleCloseDatenschutz = () => setShowDatenschutz(false);
    return (
        <>
            <ImpressionModal
                modalClose={modalClose}
                show={show}
                handleCloseImpression={handleCloseImpression}
                handleShowImpression={handleShowImpression}/>
            <DatenschutzModal
                modalClose={modalDatenClose}
                showDatenschutz={showDatenschutz}
                handleCloseDatenschutz={handleCloseDatenschutz}
                handleShowDatenschutz={handleShowDatenschutz}
            />
            <div className="row control-container d-none d-md-block ">
                <div className="col-md-4 offset-md-4 d-flex flex-column mt-3">
                      <span
                          className="dotd "
                          style={{ cursor: 'pointer' }}
                          onClick={props.zoomIn}
                      >
                                 <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
                                    <g id="Group_4533" data-name="Group 4533" transform="translate(-1822 -728)">
                                    <g id="Ellipse_43" data-name="Ellipse 43" transform="translate(1822 728)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="29" cy="29" r="29" stroke="none"/>
                                    <circle cx="29" cy="29" r="27.5" fill="none"/>
                                    </g>
                                    <line id="Line_243" data-name="Line 243" y2="26" transform="translate(1851.5 744.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_244" data-name="Line 244" y2="26" transform="translate(1864.5 757.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                      </span>
                    <span
                        className="dotd"
                        style={{ cursor: 'pointer' }}
                        onClick={props.zoomOut}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
                            <g id="Group_4530" data-name="Group 4530" transform="translate(-1831 -792)">
                                <g id="Ellipse_42" data-name="Ellipse 42" transform="translate(1831 792)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="29" cy="29" r="29" stroke="none"/>
                                    <circle cx="29" cy="29" r="27.5" fill="none"/>
                                </g>
                                <line id="Line_245" data-name="Line 245" y2="26" transform="translate(1873.5 821) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </svg>
                    </span>
                    {/*<span*/}
                    {/*  className="dot"*/}
                    {/*  style={{ cursor: 'pointer' }}*/}
                    {/*  onClick={props.setLock}*/}
                    {/*>*/}
                    {/*  <i*/}
                    {/*    className={*/}
                    {/*      props.isLock ? 'fal fa-lock Zoom-in' : 'fal fa-unlock Zoom-in'*/}
                    {/*    }*/}
                    {/*  ></i>*/}
                    {/*</span>*/}
                </div>
                <div className="d-flex mt-3 col-12">
                    <div
                        style={{
                            color: "#CCCCCC",
                            marginRight: 10,
                            // fontWeight: "bold",
                            cursor: "pointer",
                        }}
                        onClick={handleShowImpression}
                        // onClick={openImpressionModal}
                    >
                        Imprint
                    </div>
                    <div
                        style={{
                            color: "#CCCCCC",
                            // fontWeight: "bold",
                            cursor: "pointer",
                        }}
                        onClick={handleShowDatenschutz}
                    >
                        Privacy Policy
                    </div>
                </div>
            </div>
        </>
    );
}
