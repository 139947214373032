export const initialState = {
  organizations: [],
  selectedOrganization: {},
  searchedOrganizations: [],
  displaySearch: false,
  displayBrowse: false,
  desciplines: [],
  isDesciplineDetail: false,
  selectedDescipline: {},
  isBrowseFunders: false,
  funders: [],
  isFundersDetails: false,
  isFundersProjectDetails: false,
  selectedFunder: {},
  selectedFunderProject: {},
  isBrowserDesciplines: false,
  currentOrganizationTab: "projects",
  activeMarker: false,
  viewer: null,
};
