import React from 'react';
// import logo from '../images/re-logo2.png';
import logo from '../images/logo-icon.png';
import './Search.css';
export default function ImageComponent() {
    const goToInitailState = () => {
        window.location.reload();
    };
    return (
        <div className="logo" style={{ cursor: 'pointer' }}>
            <img
                className="logo-image d-none d-md-block"
                src={logo}
                onClick={goToInitailState}
            />
            <div className="d-block  mobile-logo">
                <a href="">
                    <p className="mb-0 pb-0 logo-text">RESEARCH EARTH</p>
                    <p className="logo-sologan">
                        live e-map of swiss research partnerships
                    </p>
                </a>
            </div>
        </div>

    );
}
