import React from "react";
import DatenschutzModal from "./datenschutzModal";
import ImpressionModal from "./impressionModal";
import "./Search.css";

export default function SocialMedia(props) {

    return (
        <>

        <div className="row pointer-event mt-20 d-none d-md-block mr-30">
            <div className="d-flex align-items-center justify-content-center">
                <a href="https://twitter.com/kfpe_ch" target="_blank">
						<span className="dot-without-border">
							<svg xmlns="http://www.w3.org/2000/svg" width="36.704" height="36.704"
                                 viewBox="0 0 36.704 36.704">
							  <g id="Gruppe_197" data-name="Gruppe 197" transform="translate(-2307.683 -9293.296)">
								<g id="Layer_2" transform="translate(2307.684 9293.309)">
								  <g id="Layer_1-2">
									<path id="Pfad_663" data-name="Pfad 663"
                                          d="M67.523,85.391a18.351,18.351,0,1,0-12.949-5.375A18.351,18.351,0,0,0,67.523,85.391Zm0-34.809a16.5,16.5,0,1,1-11.656,4.829A16.5,16.5,0,0,1,67.523,50.582Z"
                                          transform="translate(-49.2 -48.7)" fill="#fbfbfb"/>
								  </g>
								  <path id="icons8-twitter_1_" data-name="icons8-twitter (1)"
                                        d="M23.736,11.9a7.544,7.544,0,0,1-2.231.644A4.054,4.054,0,0,0,23.213,10.3a7.543,7.543,0,0,1-2.465.984A3.784,3.784,0,0,0,17.919,10a3.971,3.971,0,0,0-3.88,4.055,4.277,4.277,0,0,0,.1.925,10.874,10.874,0,0,1-8-4.24,4.174,4.174,0,0,0-.525,2.042,4.107,4.107,0,0,0,1.726,3.377,3.794,3.794,0,0,1-1.759-.506V15.7A4.018,4.018,0,0,0,8.7,19.678a3.673,3.673,0,0,1-1.02.146,3.93,3.93,0,0,1-.731-.075,3.917,3.917,0,0,0,3.623,2.817A7.584,7.584,0,0,1,5.75,24.3a7.282,7.282,0,0,1-.925-.058,10.67,10.67,0,0,0,5.948,1.82c7.136,0,11.04-6.18,11.04-11.541,0-.176-.005-.351-.013-.523a7.87,7.87,0,0,0,1.937-2.1"
                                        transform="translate(4.274 0.795)" fill="#fbfbfb"/>
								</g>
							  </g>
							</svg>


						</span>
                </a>
                <a target="_blank" href="https://www.linkedin.com/showcase/kfpe_ch/">
					<span className="dot-without-border">
						{/* <i className="fab fa-facebook"></i> */}
						<svg xmlns="http://www.w3.org/2000/svg" width="36.704" height="36.703"
							 viewBox="0 0 36.704 36.703">
							<g id="Group_4531" data-name="Group 4531" transform="translate(-2307.999 -9292.988)">
							<path id="Pfad_663" data-name="Pfad 663"
								  d="M67.523,85.391a18.351,18.351,0,1,0-12.949-5.375A18.351,18.351,0,0,0,67.523,85.391Zm0-34.809a16.5,16.5,0,1,1-11.656,4.829A16.5,16.5,0,0,1,67.523,50.582Z"
								  transform="translate(2258.8 9244.301)" fill="#fff"/>
							<rect id="Rechteck_138" data-name="Rechteck 138" width="3.185" height="9.833" transform="translate(2319 9307.816)"
								  fill="#fff"/>
							<path id="Pfad_677" data-name="Pfad 677" d="M15.8,13a1.7,1.7,0,0,0-.041,3.4h.02A1.7,1.7,0,1,0,15.8,13Z"
								  transform="translate(2305 9290.002)" fill="#fff" fill-rule="evenodd"/>
							<path id="Pfad_678" data-name="Pfad 678"
								  d="M27.678,20.118a3.152,3.152,0,0,0-2.872,1.624V20.349H21.621c.042.922,0,9.833,0,9.833h3.185V24.691a2.288,2.288,0,0,1,.106-.8,1.748,1.748,0,0,1,1.634-1.2c1.153,0,1.614.9,1.614,2.224v5.261h3.185V24.544C31.345,21.524,29.774,20.118,27.678,20.118Z"
								  transform="translate(2302.533 9287.698)" fill="#fff" fill-rule="evenodd"/>
							</g>
						</svg>
					</span>
                </a>

            </div>


        </div>
        </>
    );
}
