import React, { useContext, useMemo } from 'react';
import { hot } from 'react-hot-loader/root';
import {
	Viewer,
	GeoJsonDataSource,
	CameraFlyTo,
	Globe,
	Scene,
	Entity,
	ScreenSpaceCameraController,
	ImageryLayer,
	SkyAtmosphere,
	Sun,
	EllipseGraphics,
	ScreenSpaceEventHandler,
	ScreenSpaceEvent,
	Camera,
} from 'resium';
import {
	Cartesian3,
	Ion,
	Cesium,
	IonImageryProvider,
	Color,
	Math as CesiumMath,
	ScreenSpaceEventType,
	Cartographic,
	Ellipsoid,
} from 'cesium';
import './EarthView.css';
import Context from '../context/store';
import {
	getSelectedOrganizationProjectUrl,
	GET_METHOD,
	SET_BROWSE_DISPLAY,
	SET_SEARCH_DISPLAY,
	SET_SELECTED_ORGANIZATION,
	SET_VIEWER_REF,
} from '../constants/data';
import { getOrganizationById } from '../services/utils';
import Loader from './Loader';
import Marker from './Marker';
import SceneMode from 'cesium/Source/Scene/SceneMode';
import CameraEventType from 'cesium/Source/Scene/CameraEventType';
import { countBy } from 'lodash';
Ion.defaultAccessToken = process.env.REACT_APP_EARTH_TOKEN;
function EarthView({
					   data,
					   cameraFlyX,
					   cameraFlyY,
					   cameraFlyZ,
					   isLock,
					   setDefaultZoom,
					   setActiveMarker,
					   activeMarker,
					   cb,
					   removeSelectedEntityOnPintch,
					   globeDuration,
					   isCameraFlyDone,
					   setIsCameraFlyDone,
					   clickType,
					   isCameraZoomReq,
					   updateCameraFlyValue,
					   setCameraFlyX,
					   setCameraFlyY,
					   setCameraFlyZ,
				   }) {
	const [distanceStep, setDistanceStep] = React.useState(3);
	const [state, dispatch] = useContext(Context);
	const [displayLoader, setDisplayLoader] = React.useState(true);
	const [isComponentRenderFirst, setIsComponentRenderFirst] =
		React.useState(true);
	const ionBing = new IonImageryProvider({ assetId: 2 });
	const [selectedEntity, setSelectedEntity] = React.useState('');
	const [camZoomInit, setCamZoomInit] = React.useState('');
	const viewers = React.useRef(null);
	const CameraRef = React.useRef(null);
	const screenSpaceRef = React.useRef(null);
	const screenRef = React.useRef(null);
	const distanceInterval = [
		[15000000, 13500100],
		[13500100, 12000200],
		[12000200, 10500300],
		[10500300, 9000400],
		[9000400, 7500500],
		[7500500, 6000600],
		[6000600, 4500700],
		[4500700, 3000800],
		[3000800, 1500900],
		[1500900, 1000],
	];
	// console.log('isCameraZoomReq', isCameraZoomReq);
	React.useEffect(() => {
		// setIsComponentRenderFirst(true);
		if (viewers.current && viewers.current.cesiumElement) {
			viewers.current.cesiumElement.screenSpaceEventHandler.removeInputAction(
				ScreenSpaceEventType.LEFT_DOUBLE_CLICK
			);
			dispatch({
				type: SET_VIEWER_REF,
				payload: viewers.current,
			});
		}

		outerClickForZoom();
	}, [isCameraZoomReq]);
	const getSelectedOrganization = async (id) => {
		setDisplayLoader(true);
		let data = await getOrganizationById(id);
		if (data && Object.keys(data).length > 0) {
			if (data.isSuccess && !!data.payload) {
				let organization = data.payload;
				organization.tab_projects.forEach((elem) => {
					fetch(getSelectedOrganizationProjectUrl(elem.project_id), {
						method: GET_METHOD,
					})
						.then(function (response) {
							return response.json();
						})
						.then(function (myJson) {
							organization.tab_projects.filter((el) => {
								myJson.forEach((e) => {
									if (parseInt(e.projects_id) === parseInt(el.project_id)) {
										el.details = e;
										el.isOpen = false;

										const ab = document.getElementsByClassName('accordion-button')
										if(ab[0]){
											if(!ab[0].classList.contains('collapsed')){
												el.isOpen = true;
											}else{
												el.isOpen = false;
											}
										}
										// el.isOpen = false;
									}
								});
							});
							organization.tab_disciplines.forEach((el) => {
								el.isOpen = false;
							});
							let emptiness = new Promise((onSuccess) => {
								dispatch({
									type: SET_SELECTED_ORGANIZATION,
									payload: organization,
								});
								onSuccess(true);
							});
							emptiness.then((result) => {
								setTimeout(() => {
									setDisplayLoader(false);
								}, 1000);
								if (isComponentRenderFirst) {
									if (
										isComponentRenderFirst &&
										viewers.current &&
										viewers.current.cesiumElement
									) {
										var iniPos = new Cartesian3();

										iniPos =
											viewers.current.cesiumElement._cesiumWidget._scene
												._defaultView.camera;
										// debugger;
										// setCamZoomInit(iniPos.position.z);
									}
									setIsComponentRenderFirst(false);
								} else {
									setDefaultZoom(3000);
								}
								//
								setIsCameraFlyDone(true);
							});
						})
						.catch((error) => {
							console.log('error in getting organization'.error);
						});
				});
			} else {
				// console.log(data.payload);
			}
		}
	};
	const handleSelectedEntityChanged = (entity) => {
		if (!isLock) {
			setIsCameraFlyDone(false);
			if (entity?._id !== undefined) {
				setActiveMarker(false);
				// viewers.current.cesiumElement._trackedEntity = entity;
				getSelectedOrganization(entity._id);
				// setSelectedEntity(entity._id);
				if (clickType !== 'outside') {
					// cb(entity._id, "earthView");
				}
			}
		}
	};
	const handleClick = (e) => {
		// setActiveMarker(false);
	};
	let temp = false;
	let values = {};
	const screenLeftMouseDownEvent = (obj) => {
		temp = true;

		// console.log('screenLeftMouseDownEvent', obj);
		// removeSelectedEntityOnPintch();
	};
	const screenLeftMouseUpEvent = (obj) => {
		// console.log(obj.evt.position.x);
		// let value = Cartesian3.packArray();
		// let value = new Cartesian3.fromRadiansArray([
		// 	7.4711008, 8.9984456, 23.5149586, 14.9071325, 39.2539683, -6.7659369,
		// 	38.4970211, 7.0510585, -0.1828841, 5.5979975, 8.025083, 47.516939,
		// ]);
		// console.log("value cartine", value);
		if (viewers.current && viewers.current.cesiumElement) {
			let camPos =
				viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera
					._position;
			//   setCameraFlyX(camPos.x);
			//   setCameraFlyY(camPos.y);
			//   setCameraFlyZ(camPos.z);
		}
		// console.log('screenLeftMouseUpEvent', obj);
		temp = false;
	};
	const screenMouseMoveEvent = (obj) => {
		//


		if (temp) {
			// removeSelectedEntityOnPintch();
			// setIsCameraFlyDone(false);

			values = obj;
		}
	};
	const screenMouseWheelMoveEvent = (obj) => {
		//
		// console.log(obj);
		if (temp) {
			// removeSelectedEntityOnPintch();
			// setIsCameraFlyDone(false);

			values = obj;
		}
	};
	const outerClickForZoom = () => {
		if (!isComponentRenderFirst) {
			// console.log(
			// 	'viewers.current',
			// 	viewers.current.cesiumElement._cesiumWidget
			// );
			// console.log('Cesium', Cesium);
			if (viewers.current && viewers.current.cesiumElement) {
				var iniPos = new Cartesian3();

				iniPos =
					viewers.current.cesiumElement._cesiumWidget._scene._defaultView
						.camera;
				// debugger;
				// console.log('iniPos.position.z', iniPos.position.z);

				let value = 0.5 * cameraFlyZ;
				// var cartographic = new Cartographic();
				// cartographic.height = iniPos.position.z;
				// cartographic.longitude = iniPos.position.x;
				// cartographic.latitude = iniPos.position.y;
				// var newPos = new Cartesian3();
				// let test = Ellipsoid.WGS84.cartographicToCartesian(
				//   cartographic,
				//   newPos
				// );
				if (viewers.current && viewers.current.cesiumElement) {
					value = 0.5 * viewers.current.cesiumElement.camera.positionCartographic.height;
				}
				if (isCameraZoomReq.type === 'zoomOut') {

                    // console.log('Camera Height', viewers.current.cesiumElement.camera.positionCartographic.height);
                    // console.log('Camera zom init Height', camZoomInit);
					// let val = cameraFlyZ + value;
					// let val = 0.5 / value;
                    value = 0.7 * viewers.current.cesiumElement.camera.positionCartographic.height;
					let val = value + viewers.current.cesiumElement.camera.positionCartographic.height;
					// if (val < camZoomInit) {
					// 	updateCameraFlyValue(value);
						updateCameraFlyValue(val);
					// }
				} else if (isCameraZoomReq.type === 'zoomIn') {
					if(value > 250){
                        updateCameraFlyValue(value);
                    }
				}
				// viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera.setView(
				//   {
				//     position: test,
				//   }
				// );

				// let camPosistion =
				//   viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera
				//     ._position;
				// let pos = (camPosistion.z * 50) / 100;
				// if (isCameraZoomReq.type === 'zoomOut') {
				//   let newPos = camPosistion.z + pos;
				//   console.log('newPos', newPos);
				//   updateCameraFlyValue(newPos);
				// } else if (isCameraZoomReq.type === 'zoomIn') {
				//   let newPos = camPosistion.z - pos;
				//   console.log('newPos', newPos);
				//   updateCameraFlyValue(newPos);
				// }
			}

			//   if (viewers.current && viewers.current.cesiumElement) {
			//     let camerPosition =
			//       viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera
			//         ._position.z;
			//     if (isCameraZoomReq.type === 'zoomOut') {
			//       let number = parseInt(camerPosition);
			//       if (number < 14999999) {
			//         let pos = (camerPosition * 50) / 100;
			//         if (pos > 14999999) {
			//           camerPosition = 14999999;
			//         } else {
			//           camerPosition = camerPosition + pos;
			//         }
			//       }
			//     } else {
			//       if (camerPosition <= 1000) {
			//         camerPosition = 1000;
			//       } else {
			//         let number = parseInt(camerPosition);
			//         if (number <= 1000) {
			//           let percentValue = (camerPosition * 50) / 100;
			//           let pos = camerPosition - percentValue;
			//           if (pos === 0) {
			//             camerPosition = 1000000;
			//           } else {
			//             camerPosition = 1000;
			//           }
			//         } else {
			//           let percentValue = (camerPosition * 50) / 100;
			//           let pos = camerPosition - percentValue;
			//           camerPosition = pos;
			//         }
			//       }
			//     }
			//     // let a = camerPosition + 3000800;
			//     // camerPosition = camerPosition + a;
			//     // console.log("camerPosition", CameraRef);
			//     updateCameraFlyValue(camerPosition);
			//     // viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position =
			//     // 	Cartesian3.fromDegrees(cameraFlyX, cameraFlyY, 2003242);
			//     // console.log(
			//     // 	viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera
			//     // 		._position
			//     // );
			//   }
		}
	};
	const handleWheel = (delta) => {
		// console.log(323);
		// console.log(delta);
		// setDefaultZoom(3000800);
		if (viewers.current && viewers.current.cesiumElement) {
			// viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position =
			// 	Cartesian3.fromDegrees(cameraFlyX, cameraFlyY, cameraFlyZ);
			const cameraZ = viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position.z;
			// console.log(viewers.current.cesiumElement);
			// console.log(viewers.current.cesiumElement.camera.positionCartographic);
			// console.log(viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position);
			if(cameraZ > 2000){
				// console.log('cameraFlyZ Previous ',parseFloat(viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position.z));
				// console.log('cameraFlyZ Previous ',cameraFlyZ);
				// updateCameraFlyValue(viewers.current.cesiumElement.camera.positionCartographic.height);
				// updateCameraFlyZ(viewers.current.cesiumElement.camera.positionCartographic.height);
				// viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position =
				// 	Cartesian3.fromDegrees(cameraFlyX, cameraFlyY, cameraFlyZ);
			}
			// console.log(viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position);
			// console.log('cameraFlyZ After ', cameraFlyZ);

			// Cartesian3.fromDegrees(14753532.112381184, 3117225.270615776, 163768.794571187);
		}
		if (viewers.current && viewers.current.cesiumElement) {
			// console.log(viewers.current.cesiumElement);
			// console.log(viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position);
			if(viewers.current.cesiumElement._entityView && viewers.current.cesiumElement._entityView.entity){
				viewers.current.cesiumElement._entityView.entity._selected = false;
				viewers.current.cesiumElement._entityView.entity._tracked = false;
				viewers.current.cesiumElement._selectedEntity = false;
				// viewers.current.cesiumElement._trackedEntity = false;
				// viewers.current.cesiumElement._entityView?.entity._selected = true;
				// console.log(viewers.current.cesiumElement._entityView?.entity._selected);
			}
			if(viewers.current.cesiumElement._selectedEntity && viewers.current.cesiumElement._selectedEntity){
				viewers.current.cesiumElement._selectedEntity._selected = false;
				viewers.current.cesiumElement._selectedEntity._tracked = false;
				// viewers.current.cesiumElement._selectedEntity = false;
				// viewers.current.cesiumElement._trackedEntity = false;
				// viewers.current.cesiumElement._entityView?.entity._selected = true;
				// console.log('._selectedEntity');
				// console.log(viewers.current.cesiumElement._selectedEntity);
			}
			if(viewers.current.cesiumElement._trackedEntity && viewers.current.cesiumElement._trackedEntity){
				viewers.current.cesiumElement._trackedEntity._selected = false;
				viewers.current.cesiumElement._trackedEntity._tracked = false;
				// viewers.current.cesiumElement._selectedEntity = false;
				// viewers.current.cesiumElement._trackedEntity = false;
				// viewers.current.cesiumElement._entityView?.entity._selected = true;
				// console.log('._trackedEntity');
				// console.log(viewers.current.cesiumElement._trackedEntity);
			}
			// console.log(viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position);
			// viewers.current.cesiumElement._cesiumWidget._scene._defaultView.camera._position =
			// 	Cartesian3.fromDegrees(14753532.112381184, 3117225.270615776, 1000.79);

			// viewers.entities.add(entity);
		}
	};
	const movePointer = (e, id) => {
		// console.log('dsdfsdfs', e);

		if (!isLock) {
			dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
			cb(id, 'earthView');
		}
		// (e) => cb(e.id);
	};
	const DarwImageProvidery = () => {
		return useMemo(() => (
			<ImageryLayer
				imageryProvider={ionBing}
				contrast={1.1}
				brightness={1.1}
				hue={0.1}
				saturation={1.5}
				maximumZoomDistance={13000800}
			/>
		));
	};
	const viewerMouseMove = (value) => {
		// console.log('viewerMouseMove', value);
		// removeSelectedEntityOnPintch();
	};
	const cameraRightDrag = (value) => {
		// console.log('Right_DRAG', value);
		// removeSelectedEntityOnPintch();
	};
	const cameraMoveEnd = (e) => {
		// console.log('cameraMoveEnd', e);
	};
	const entityOnMouseEnter = (e) => {
		// console.log('entityOnMouseEnter', e);
	};
	const getonImageryLayersUpdate = () => {
		// console.log('getonImageryLayersUpdate');
		// if (isGlobeLoad) {

		// }
		// setDisplayLoader(false);
	};

	return (

		<div className="earth-container">
			<Viewer
				// onClick={handleClick}
				id="cesiumContainer"
				ref={viewers}
				full
				animation={false}
				timeline={false}
				fullscreenButton={false}
				navigationHelpButton={false}
				homeButton={false}
				sceneModePicker={false}
				baseLayerPicker={false}
				geocoder={false}
				infoBox={false}
				onSelectedEntityChange={handleSelectedEntityChanged}
				skyBox={false}
				selectionIndicator={false}
				onWheel={handleWheel}

				// shouldAnimate={true}
				// allowDataSourcesToSuspendAnimation={true}

				// selectedEntity={selectedEntity}
			>
				<Scene
					backgroundColor={Color.BLACK}
					mode={SceneMode.SCENE3D}
					morphTime={1}
					ref={screenRef}
				/>

				{/* <Camera zoomIn={defaultZoomAmount}/> */}
				<ScreenSpaceCameraController
					ref={screenSpaceRef}
					enableZoom={true}
					//   maximumZoomDistance={19000000}
					minimumZoomDistance={1000}
				/>
				{/* {Object.keys(selectedOrganization).length > 0 && (
          <Entity
            position={Cartesian3.fromDegrees(
              temp
                ? values.evt.endPosition.x
                : parseFloat(selectedOrganization?.organization_data.lng),
              temp
                ? values.evt.endPosition.y
                : parseFloat(selectedOrganization?.organization_data.lat)
            )}
            ellipse={{
              semiMinorAxis: 200000.0,
              semiMajorAxis: 200000.0,
              fill: false,
              height: 200000.0,
              extrudedHeight: 200000.0,
              material: Color.GREEN,
              outline: true, // height must be set for outline to display
              outlineColor: Color.LAWNGREEN,
              outlineWidth: 3000800,
            }}
          ></Entity>
        )} */}

				<ScreenSpaceEventHandler>
					<ScreenSpaceEvent
						action={(evt) =>
							screenLeftMouseDownEvent({ name: 'Left Click Down', evt })
						}
						type={ScreenSpaceEventType.LEFT_DOWN}
					/>
					<ScreenSpaceEvent
						action={(evt) =>
							screenLeftMouseUpEvent({ name: 'Left Click Up', evt })
						}
						type={ScreenSpaceEventType.LEFT_UP}
					/>

					<ScreenSpaceEvent
						action={(evt) => screenMouseMoveEvent({ name: 'MOUSE MOVE', evt })}
						type={ScreenSpaceEventType.MOUSE_MOVE}
					/>
					<ScreenSpaceEvent
						action={(evt) => screenMouseWheelMoveEvent(evt)}
						type={ScreenSpaceEventType.WHEEL}
					/>
				</ScreenSpaceEventHandler>
				<Globe
					// show={true}
					show={true}
					// morphin={true}
					showGroundAtmosphere={true}
					showWaterEffect={true}
					atmosphereSaturationShift={0.4}
					showSkirts={true}
					atmosphereBrightnessShift={-0.1}
					onTileLoadProgress={getonImageryLayersUpdate}
				/>
				<SkyAtmosphere
					hueShift={1}
					saturationShift={1.5}
					brightnessShift={0.5}
				/>
				{/* <DarwImageProvidery /> */}
				<Camera
					onMoveEnd={(e) => entityOnMouseEnter(e)}
					ref={CameraRef}
					// right={Math.atan(20)}
					// LEFT_DRAG={onPinchEndCb}
					// onMoveEnd={cameraMoveEnd}

					// defaultZoomAmount={cameraFlyZ}
					// direction={Cartesian3.fromDegrees(cameraFlyX, cameraFlyY, cameraFlyZ)}
				/>
				<CameraFlyTo
					destination={Cartesian3.fromDegrees(
						cameraFlyX,
						cameraFlyY,
						cameraFlyZ
					)}
					// orientation={{
					// 	heading: 0,
					// 	pitch: CameraRef.pitch,
					// 	roll: CameraRef.roll,
					// }}
					duration={globeDuration}
					maximumHeight={cameraFlyZ}
					once={isCameraFlyDone}
					onComplete={(e) => {
						// alert("camera ply end");
						if (
							Object.keys(state.selectedOrganization).length > 0 &&
							!isComponentRenderFirst
						) {
							setActiveMarker(true);
						}
						if (displayLoader && !isComponentRenderFirst) {
							// setDisplayLoader(false);
						}
						if (data && data.length > 0 && isComponentRenderFirst) {
							// console.log('camera fly complete');
							// setDisplayLoader(true);
							// setIsComponentRenderFirst(false);

							setTimeout(() => {
								// setDefaultZoom(3000800);
								// getSelectedOrganization(data[0].id);
								// cb(id, "earthView");
								cb(data[0].id, 'earthView');
								setIsCameraFlyDone(false);
							}, 30);
							//
						}
					}}
				/>
				{data && data.length > 0
					? data.map((d, i) => {
						return (
							<Entity
								position={Cartesian3.fromDegrees(
									d.geometry.coordinates[0],
									d.geometry.coordinates[1]
								)}
								height={150}
								onClick={(e) => movePointer(e, d.id)}
								id={d.id}
								name={d.name}
								selected={d.isSelected}
								tracked={false}
								point={{
									pixelSize: 7,
									color: Color.LAWNGREEN,
									outlineColor: Color.WHITE,
									// outlineWidth: 1.0,
								}}
							></Entity>
							// <GeoJsonDataSource
							// 	data={d}
							// 	key={i}
							// 	show={true}
							// 	fill={"red"}
							// 	clampToGround={true}
							// 	onLoad={(dataSource) => {
							// 		dataSource.entities.values.forEach((e) => {
							// 			console.log
							// 			e.entities = { color: Color.GREEN, pixelSize: 10 };
							// 		});
							// 	}}
							// />
						);
					})
					: ''}
			</Viewer>
			{displayLoader && <Loader />}
			{!displayLoader && Object.keys(state.selectedOrganization).length > 0 && (
				<Marker
					selectedOrganization={state.selectedOrganization}
					activeMarker={activeMarker}
				/>
			)}
		</div>
	);
}

export default hot(EarthView);
