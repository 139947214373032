import { countBy, debounce } from "lodash";
import React, { useCallback, useContext } from "react";
import {
	getSelectedOrganizationProjectUrl,
	GET_METHOD,
	SET_ACTIVE_MARKER,
	SET_BROWSE_DISPLAY,
	SET_SEARCHED_ORGANIZATIONS,
	SET_SEARCH_DISPLAY,
	SET_SELECTED_ORGANIZATION, SET_DESCIPLINE_DETAILS_OPEN,
} from "../constants/data";
import Context from "../context/store";
import { getOrganizationById, searchOrganization } from "../services/utils";
export default function Search(props) {
	const [state, dispatch] = useContext(Context);

	const handleSearchClose = () => {
		document.getElementById("search").value = "";
		dispatch({ type: SET_SEARCHED_ORGANIZATIONS, payload: [] });
		dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
	};
	const debouncedSearchOrganizations = useCallback(
		debounce((searchValue) => getOrganizations(searchValue), 1000),
		[]
	);
	const getOrganizations = async (value) => {
		dispatch({ type: SET_SEARCH_DISPLAY, payload: true });
		let data = await searchOrganization(value);
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				let temp = data.payload;
				let dropDownData = [];
				temp.forEach((element) => {
					dropDownData.push({
						key: element.id,
						text: element.title,
						value: element.id,
					});
				});
				dispatch({ type: SET_SEARCHED_ORGANIZATIONS, payload: dropDownData });
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleSearchChange = async (e) => {

		// dispatch({ type: SET_BROWSE_DISPLAY, payload: true });
		debouncedSearchOrganizations(e.target.value);
	};
	const handleClick = (e) => {
		// debugger;
		props.setActiveMarker(false);
		// props.setDefaultZoom();
		props.cb(e.target.id, "outside");
		dispatch({ type: SET_DESCIPLINE_DETAILS_OPEN, payload: e.target.id });
		props.setIsCameraFlyDone(false);
		if (state.displaySearch) {
			dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		}
		document.getElementById("search").value = "";

		console.log('showing searched ')
		// getSelectedOrganization(e.target.id);
	};
	const getSelectedOrganization = async (id) => {
		debugger;
alert(19);
		if (state.displaySearch) {
			dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
		}
		let data = await getOrganizationById(id);
		if (data && Object.keys(data).length > 0) {
			if (data.isSuccess) {
				let organization = data.payload;
				organization.tab_projects.forEach((elem) => {
					fetch(getSelectedOrganizationProjectUrl(elem.project_id), {
						method: GET_METHOD,
					})
						.then(function (response) {
							return response.json();
						})
						.then(function (myJson) {
							organization.tab_projects.filter((el) => {
								myJson.forEach((e) => {
									if (parseInt(e.projects_id) === parseInt(el.project_id)) {
										el.details = e;
									}
								});
							});
							dispatch({
								type: SET_SELECTED_ORGANIZATION,
								payload: organization,
							});
						})
						.catch((error) => {
							console.log("error in getting organization".error);
						});
				});
				dispatch({ type: SET_SELECTED_ORGANIZATION, payload: data.payload });
			} else {
				console.log(data.payload);
			}
		}
	};
	return (
		<>
			<div
				className={`search-form ${
					state.displaySearch && state.searchedOrganizations.length > 0
						? "show"
						: ""
				}`}
				style={{ marginBottom: "10px" }}
			>
				<div class="input-grup">
					<input
						autoComplete="off"
						id="search"
						type="text"
						class="form-control"
						name=""
						placeholder="Search"
						onFocus={() =>
							dispatch({ type: SET_BROWSE_DISPLAY, payload: false })
						}
						style={{ cursor: "text" }}
						onChange={handleSearchChange}
					/>
					<ul class="d-flex icons-search">
						<li class="justify-content-center align-self-center">
							<span style={{ cursor: "pointer" }} onClick={handleSearchClose}>
								<svg xmlns="http://www.w3.org/2000/svg" width="24.243" height="24.243" viewBox="0 0 24.243 24.243">
								  <g id="Group_6001" data-name="Group 6001" transform="translate(-1460.379 -485.379)">
									<g id="Group_5969" data-name="Group 5969" transform="translate(1060 -30)">
									  <line id="Line_362" data-name="Line 362" x2="20" y2="20" transform="translate(402.5 517.5)" fill="none" stroke="#393939" stroke-linecap="round" stroke-width="3"/>
									  <line id="Line_363" data-name="Line 363" x1="20" y2="20" transform="translate(402.5 517.5)" fill="none" stroke="#393939" stroke-linecap="round" stroke-width="3"/>
									</g>
								  </g>
								</svg>

							</span>
						</li>
						<li class="justify-content-center align-self-center">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="36.823" height="36.823" viewBox="0 0 36.823 36.823">
								  <g id="search_icon" style={{isolation: "isolate"}}>
									<g id="Group_3765" data-name="Group 3765" transform="translate(0 18.411) rotate(-45)">
									  <g id="Ellipse_14" data-name="Ellipse 14" transform="translate(0 0)" fill="none" stroke="#393939" stroke-width="3">
										<circle cx="13.019" cy="13.019" r="13.019" stroke="none"/>
										<circle cx="13.019" cy="13.019" r="11.519" fill="none"/>
									  </g>
									  <line id="Line_227" data-name="Line 227" y2="8.285" transform="translate(13.315 25.15)" fill="none" stroke="#393939" stroke-linecap="round" stroke-width="3"/>
									</g>
								  </g>
								</svg>
							</span>
						</li>
					</ul>
				</div>

				<div
					className={`search-dropdown ${
						state.displaySearch && state.searchedOrganizations.length > 0
							? "show"
							: ""
					}`}
				>
					{state.displaySearch && state.searchedOrganizations.length > 0 && (
						<ul>
							{state.searchedOrganizations.map((elem) => (
								<li>
									<span id={elem.key} onClick={(e) => handleClick(e)}>
										<p className="desciplines-title" id={elem.key}>{elem.text}</p>
										<i id={elem.key} class="fal fa-globe"></i>
									</span>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</>
	);
}
