import React, { useState } from 'react';
export default function MobileInfoBox({ showTip }) {
  return (
    <div>
      <div className="info-container mobile-info-container">
        <div className="info-tab-mobile info-container">
          <div
            className="company-title bg-white d-block info-container"
            style={{ color: '#9C9C9C' }}
          >
            <div className="close-icon" onClick={showTip}>
              <span>X</span>
            </div>
            <h1 className="info-title info-container" style={{ color: '#000' }}>
              How to Use Research Earth
            </h1>

            <p className="info-container">
              Explore the Research by{' '}
              <strong style={{ color: '#000' }} className="info-container">
                navigation the globe.
              </strong>{' '}
              Click on the dots to learn more about a research Institution. Its
              projects, network and disciplines.
            </p>
            <p className="info-container">
              Use the{' '}
              <strong style={{ color: '#000' }} className="info-container">
                search field
              </strong>{' '}
              with your own keywords to find relevant institutions.
            </p>
            <p className="info-container">
              If you search for research institutions working in specfic
              disciplines or for projects that are supported by a specific
              funder you can also use the provideed{' '}
              <strong style={{ color: '#000' }} className="info-container">
                browser functions.
              </strong>
            </p>
            <p className="info-container">Enjoy your explorations!</p>
            <div className="info-container" style={{ color: '#9C9C9C' }}>
              <p className="mb-0 info-container">
                For any enquiries and comments.
              </p>
              <p className="mb-0 info-container">Please contact us: </p>
              <p>info@researchearth.org</p>
            </div>
            {/* <ul style={{ listStyle: "none" }}>
							<li></li>
							<li style={{ marginBottom: "10px" }}>
								{" "}
								<span>
									Explore the Research by <strong>navigation the globe.</strong>{" "}
									Click on the dots to learn more about a research Institution.
									Its projects, network and disciplines.
								</span>
							</li>
							<li>
								{" "}
								<span>
									It has survived not only five centuries, but also the leap
									into electronic typesetting, remaining essentially unchanged.
									It was popularised in the 1960s with the release of Letraset
									sheets containing Lorem Ipsum passages, and more recently with
									desktop publishing software like Aldus PageMaker including
									versions of Lorem Ipsum
								</span>
							</li>
						</ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}
