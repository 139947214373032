import React from 'react';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';

export default function OverlayComponent({
  organizations,
  zoomOut,
  zoomIn,
  setLock,
  isLock,
  setActiveMarker,
  activeMarker,
  setDefaultZoom,
  cb,
  removeSelectedEntityOnPintch,
  globeDuration,
  setIsCameraFlyDone,
  showTip,
}) {
  return (
    <div className="overlay-container">
      <HeaderComponent
        isLock={isLock}
        setLock={setLock}
        zoomOut={zoomOut}
        zoomIn={zoomIn}
        showTip={showTip}
      />
      <FooterComponent
        data={organizations}
        setActiveMarker={setActiveMarker}
        activeMarker={activeMarker}
        setDefaultZoom={setDefaultZoom}
        cb={cb}
        removeSelectedEntityOnPintch={removeSelectedEntityOnPintch}
        globeDuration={globeDuration}
        setIsCameraFlyDone={setIsCameraFlyDone}
        isLock={isLock}
        setLock={setLock}
        zoomOut={zoomOut}
        zoomIn={zoomIn}
      />
    </div>
  );
}
