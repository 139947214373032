import React from "react";
import "./Search.css";
import SideTab from "./SideTab";
import SocialMedia from "./SocialMedia";
import ControlComponent from "./ControlComponent";
export default function FooterComponent(props) {
	return (
		<div className="footer-container">
			<SideTab
				data={props.data}
				setActiveMarker={props.setActiveMarker}
				activeMarker={props.activeMarker}
				setDefaultZoom={props.setDefaultZoom}
				cb={props.cb}
				removeSelectedEntityOnPintch={props.removeSelectedEntityOnPintch}
				globeDuration={props.globeDuration}
				setIsCameraFlyDone={props.setIsCameraFlyDone}
			/>
			<ControlComponent
				isLock={props.isLock}
				setLock={props.setLock}
				zoomOut={props.zoomOut}
				zoomIn={props.zoomIn}
			/>
		</div>
	);
}
