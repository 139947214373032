import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ImpressionModal({ modalClose,show,handleShowImpression,handleCloseImpression }) {
	return (
		<>
			<Modal
				show={show}
				onHide={handleCloseImpression}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="customize_model"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h1 className="info-title " style={{ color: "#000",marginLeft:"15px" }}>
							Imprint
						</h1>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<div className="" style={{ color: "#9C9C9C" }}>


						<p className="">
							Research Earch was developed by the Swiss Programme for Research
							on Global Issues for Development (r4d programme, www.r4d.ch) and
							the Swiss Commission for Research Partnerships with Developing
							Countries (KFPE, <a href="www.kfpe.ch">www.kfpe.ch</a>).
						</p>
						<p>
							The database is constantly expanding by newly funded Swiss research partnership projects. Projects are depicted on the live e-map until two years after official project end date.
						</p>
						<p className="mb-0">Publishers:</p>
						<p className="mb-0">Swiss National Science Foundation (SNSF)</p>
						<p className="mb-0">Wildhainweg 3</p>
						<p className="mb-0">3001 Berne</p>
						<p className="mb-0">Phone : +41313082222</p>
						<p className="mb-0">E-mail: <a href="mailto:r4d@snf.ch">r4d@snf.ch</a></p>
						<p className="mb-0">Website: <a href="https://www.r4d.ch/ " target="_blank">www.r4d.ch </a> & <a href="https://snf.ch/" target="_blank">www.snf.ch</a></p>
						<div className="mt-2" style={{ color: "#9C9C9C" }}>
							<p className="mb-0 ">
								Swiss Commission for Research Partnerships with Developing
								Countries (KFPE)
							</p>
							<p className="mb-0 ">Postfach </p>
							<p className="mb-0 ">3001 Bern</p>
							<p className="mb-0 ">Phone: +41313069349</p>
							<p className="mb-0 ">E-mail: <a href="mailto:kfpe@scnat.ch">kfpe@scnat.ch</a></p>
							<p className="mb-0">Website: <a href="https://www.r4d.ch " target="_blank">www.r4d.ch </a> & <a href="https://snf.ch" target="_blank">www.snf.ch</a></p>
							<p className="mb-0 mt-2">
								Technical implementation and design:
							</p>
							<p>OVA Partner (<a href="www.ova-partner.ch" target="_blank">www.ova-partner.ch</a>)</p>
						</div>
					</div>
				</Modal.Body>

			</Modal>
		</>
	);
}
