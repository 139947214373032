import React from "react";
import {Modal} from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton'
export default function DatenschutzModal({ modalClose,showDatenschutz,handleShowDatenschutz,handleCloseDatenschutz }) {
	return (
		<>

			<Modal
				show={showDatenschutz}
				onHide={handleCloseDatenschutz}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="customize_model"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h1 className="info-title " style={{ color: "#000",marginLeft:"15px" }}>
							Privacy policy
						</h1>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<div className="" style={{ color: "#9C9C9C" }}>

						<p className="">
							Access to research-earth.ch and usage thereof shall be conditional to compliance with the terms stated below..
						</p>
						<p className="">
							1. Content of the online pages
							The KFPE and SNSF take all reasonable efforts to ensure that research-earth.ch functions properly and that the information published is accurate, up-to-date and complete, however, it cannot guarantee this. As far as legally permitted, KFPE and SNSF shall provide no guarantees and accepts no liability in connection with the use of research-earth.ch. The KFPE and SNSF accept no liability for errors, omissions, mistakes or deficiencies of any kind within research-earth.ch.
							Liability claims against the KFPE and SNSF which relate to material or abstract damages resulting from the use or non-use of the information provided or from the use of erroneous or incomplete information, from the misuse of the online connection or from technical faults of any kind are strictly excluded, provided that no demonstrable criminal intent or gross negligence can be ascribed to the KFPE and SNSF.
							Furthermore, the KFPE and SNSF are not liable for any damage or corruption caused by viruses on computers or other terminal equipment in connection with visiting, using or downloading data of any kind from research-earth.ch. The users are personally responsible for protecting their equipment properly from viruses and other risks on the Internet.
							The KFPE and SNSF reserve the express right, without special announcement, to change, supplement or delete parts of the site or the entire service or
							to discontinue publication temporarily or permanently.
						</p>
						<p className="">
							2. Copyright

							The contents of research-earth.ch is protected in their entirety by copyright. Downloading, printing or saving data from research-earth.ch is permitted, provided that reference is made to the source. All rights to downloaded, printed or stored data from research-earth.ch remain the property of the KFPE,  SNSF, SDC or of other copyright owners. The commercial use of research-earth.ch or elements thereof is not permitted.
							The SNSF and other Swiss research institutions and funders publish comprehensive data and statistics on their funding activities in accordance with stringent data quality and security principles. The SNSF and Swiss research institutions and funders are not responsible for any processing and publication of such data by third parties. Third parties must clearly designate their own data compilations if the latter are not authorised by the KFPE and SNSF.
							All trademarks and logos which appear anywhere on the website and which are protected, where applicable, by third parties are subject to the provisions of copyright in question and the ownership right on the part of the registered copyright holder. The fact that a trademark is merely mentioned does not mean third parties do not hold any copyrights.
							The copyrights to the linked websites are not infringed by following a link on the websites of the KFPE and SNSF. </p>
						<p className="">

							3. References and
							links
							The KFPE and SNSF accept no liability and are not responsible for the availability or content of external websites that are accessed via links. The KFPE and SNSF do not exercise any influence over the current and future design, content or copyright of the linked pages. The provider of the linked site is solely liable for illegal, erroneous or incomplete contents and particularly for damages that result from the use or non-use of such information. Following a link is at the user’s own risk.

						</p>
						<p className="">
							4. Data protection
							It is possible to input personal or commercial data (e-mail addresses, names, addresses etc.). Disclosure of this data on the part of the user is on an expressly voluntary basis.
							Data and information entered by users will be treated confidentially by the KFPE and SNSF and they will not be forwarded to third parties. The registered users thereby declare that they agree to receive information from the KFPE and SNSF according to their stated profile. Processing of personal data will always comply with the applicable data protection laws.
							To identify trends and improve the quality of our online services, a few non-personal items of usage data, such as your IP address, the website you last visited, which browser you are using, date and time, etc., are recorded ("Google Analytics"). The data is anonymised.
							Every time a visit is made to research-earth.ch, anonymous data about the users is stored. This includes, for example, the users’ computer IP address and browser information. However, users cannot be identified personally on the basis of this information. The sole purpose of such information is to make research-earth.ch as user-friendly as possible.
							When visiting research-earth.ch, information is also stored on the users’ computers. This information is in the form of a “cookie” and helps research-earth.ch to adapt to the individual requirements of the users. Should users wish to prevent this, the browser can be set to refuse to accept cookies or issue a warning.
						</p>
						<p className="">
							5. Legal effect of this exclusion of liability
							Should parts or individual formulations in this text no longer be, or not be entirely, compliant with current statutory provisions, the remainder of the document retains its validity.
							The KFPE and SNSF reserve the express right to amend the provisions herein at any time. The version published on the Internet is the valid version. The users of research-earth.ch are responsible for informing themselves at regular intervals about amendments that have been made in the interim.
						</p>
						<p className="">
							6. Applicable law and venue of jurisdiction
							For all disputes ensuing as a result of the use of research-earth.ch, Swiss law shall apply exclusively. The place of jurisdiction is Berne.
						</p>

					</div>
				</Modal.Body>

			</Modal>

		</>
	);
}
