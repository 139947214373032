import React, { useReducer } from 'react';
import EarthView from './components/EarthView';
import OverlayComponent from './components/overlayComponent';
import { SET_ORGANIZATIONS } from './constants/data';
import { reducer } from './context/reducer';
import { Provider } from './context/store';
import { getAllOrganizations } from './services/utils';
import { initialState } from './store/store';
import _ from 'lodash';
import MobileInfoBox from './components/MobileInfoBox';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cameraZoomState, setCameraZoomState] = React.useState(20000000);
  const [zoomInValue, setZoomInValue] = React.useState(10050000);
  const [isLock, setIsLock] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState(false);
  const [cameraFlyX, setCameraFlyX] = React.useState(0);
  const [cameraFlyY, setCameraFlyY] = React.useState(0);
  const [cameraFlyZ, setCameraFlyZ] = React.useState(19000000);
  const [globeDuration, setGlobeDuration] = React.useState(1.0);
  const [isCameraFlyDone, setIsCameraFlyDone] = React.useState(false);
  const [clickType, setClickType] = React.useState('');
  const [active, setActive] = React.useState(false);
  const showTip = () => {
    setActive(!active);
  };
  const [isCameraZoomReq, setIsCameraZoomReq] = React.useState({
    type: 'init',
    value: 15000000,
  });
  //   const cameraFlyX = 30;
  //   const cameraFlyY = 15;
  const zoomOutValue = 10050000;
  const [organizations, setOrganizations] = React.useState([]);
  React.useEffect(() => {
    getOrganizations();
  }, []);
  const setLock = () => {
    setIsLock(!isLock);
  };
  // console.log(isLock);
  const setDefaultZoom = (value) => {
    // console.log('app default camera zoom', value);

    setCameraFlyZ(value);
  };
  const updateCameraFlyValue = (value) => {
    // debugger;
    console.log('Update Camera Position ',value);
    setCameraFlyZ(value);
  };
  const zoomOut = () => {
    // if (!isLock) {
    // if (cameraZoomState < 1000) {
    setIsCameraFlyDone(false);
    let value = { type: 'zoomOut', value: isCameraZoomReq + 3000000 };
    setIsCameraZoomReq(value);
    // // setZoomInValue(3000000);
    // let currentZoomState = cameraZoomState;
    // let updateZoomValue = (currentZoomState += zoomOutValue);
    // let value = cameraFlyZ + 3000000;
    // setCameraFlyZ(value);
    // setCameraZoomState(value);
    // }
    // }
  };
  const zoomIn = () => {
    // if (!isLock) {
    // if (!isLock) {
    // if (cameraZoomState < 1000) {
    setIsCameraFlyDone(false);
    let value = { type: 'zoomIn', value: isCameraZoomReq + 3000000 };
    setIsCameraZoomReq(value);
    // // setZoomInValue(3000000);
    // let currentZoomState = cameraZoomState;
    // let updateZoomValue = (currentZoomState += zoomOutValue);
    // let value = cameraFlyZ - 3000000;
    // setCameraFlyZ(value);
    // setCameraZoomState(value);
  };
  const getOrganizations = async () => {
    let data = await getAllOrganizations();
    if (data && data?.payload?.length > 0) {
      if (data.isSuccess) {
        let temp = [];
        data.payload.forEach((element, index) => {
          // if (index === 0) {
          // 	setCameraFlyX(parseFloat(element.lng));
          // 	setCameraFlyY(parseFloat(element.lat));
          // }
          temp.push({
            type: 'Feature',
            id: element.id,
            isSelected: false,
            properties: {
              name: element.title,
            },
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(element.lng), parseFloat(element.lat)],
            },
          });
        });
        setOrganizations(temp);
        dispatch({ type: SET_ORGANIZATIONS, payload: temp });
      } else {
        alert(data.payload);
      }
    }
  };
  const getSelectedEntity = (id, type) => {
    if (!isLock) {
      id = Number(id);
      let orgs = [...organizations];
      orgs = _.forEach(orgs, function (value) {
        value.isSelected = false;
      });

      if (id && organizations && organizations.length > 0) {
        // setGlobeDuration(2);
        let index = organizations.findIndex((x) => x.id === id);
        if (index > -1) {
          orgs[index].isSelected = true;
          let entity = organizations[index];
          setClickType(type);
          setCameraFlyX(entity.geometry.coordinates[0]);
          setCameraFlyY(entity.geometry.coordinates[1]);
          setOrganizations(orgs);
        }
      }
    }
  };
  const removeSelectedEntityOnPintch = () => {
    let findSelectedValue = organizations.findIndex(
      (x) => x.isSelected === true
    );
    if (findSelectedValue > -1) {
      let orgs = [...organizations];
      orgs[findSelectedValue].isSelected = false;
      // orgs = _.forEach(orgs, function (value) {
      // 	value.isSelected = false;
      // });
      setOrganizations(orgs);
    }
  };
  return (
    <>
      <Provider value={[state, dispatch]}>
        <EarthView
          data={organizations}
          cameraFlyX={cameraFlyX}
          cameraFlyY={cameraFlyY}
          cameraFlyZ={cameraFlyZ}
          isLock={isLock}
          setDefaultZoom={setDefaultZoom}
          setActiveMarker={setActiveMarker}
          activeMarker={activeMarker}
          cb={getSelectedEntity}
          removeSelectedEntityOnPintch={removeSelectedEntityOnPintch}
          globeDuration={globeDuration}
          isCameraFlyDone={isCameraFlyDone}
          setIsCameraFlyDone={setIsCameraFlyDone}
          clickType={clickType}
          isCameraZoomReq={isCameraZoomReq}
          updateCameraFlyValue={updateCameraFlyValue}
        />
        <OverlayComponent
          data={organizations}
          isLock={isLock}
          setLock={setLock}
          zoomOut={zoomOut}
          zoomIn={zoomIn}
          setActiveMarker={setActiveMarker}
          activeMarker={activeMarker}
          setDefaultZoom={setDefaultZoom}
          cb={getSelectedEntity}
          removeSelectedEntityOnPintch={removeSelectedEntityOnPintch}
          globeDuration={globeDuration}
          setIsCameraFlyDone={setIsCameraFlyDone}
          showTip={showTip}
        />
        {active ? <MobileInfoBox showTip={showTip} /> : ''}
      </Provider>
    </>
  );
}

export default App;
