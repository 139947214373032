import React from 'react';
import ControlComponent from './ControlComponent';
import ImageComponent from './ImageComponent';
import MobileInfoBox from './MobileInfoBox';
import SocialMedia from "./SocialMedia";

export default function HeaderComponent(props) {
  return (
    <div className="header-container">
      <div className="d-flex justify-content-between align-items-center w-100">
        <ImageComponent />
        <div className="d-block d-md-none">
          <div className="info-container mobile-info-container">
            <span className="dot info  info-container" onClick={props.showTip}>
                 <svg id="Component_175_1" data-name="Component 175 – 1" xmlns="http://www.w3.org/2000/svg" width="42.651" height="39.89" viewBox="0 0 42.651 39.89">
                  <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="21.03" cy="19.649" rx="21.03" ry="19.649" opacity="0.669"/>
                  <path id="Path_2903" data-name="Path 2903" d="M21.326,0C33.1,0,42.651,8.93,42.651,19.945S33.1,39.89,21.326,39.89,0,30.96,0,19.945,9.548,0,21.326,0Z" fill="#fff" opacity="0.28"/>
                  <path id="Path_2904" data-name="Path 2904" d="M15.3,26.364a2.152,2.152,0,0,1-4.3,0V15.606a2.152,2.152,0,0,1,4.3,0ZM13.152,11.3A2.152,2.152,0,1,1,15.3,9.152,2.152,2.152,0,0,1,13.152,11.3Z" transform="translate(8.104 2.053)" fill="#fff"/>
                </svg>
            </span>
          </div>
        </div>
      </div>

        <SocialMedia />

    </div>
  );
}
