import React, { useState } from 'react';


import $ from 'jquery';

export default function InfoBox() {
  let timeout;
  const [active, setActive] = useState(false);
  const showTip = () => {
    setActive(true);
  };

  const hideTip = (e) => {
    setActive(false);
    // if (
    //   e &&
    //   e.target &&
    //   e.target.classList &&
    //   e.target.classList.contains('info-container')
    // ) {
    // } else {
    //   clearInterval(timeout);
    //   setActive(false);
    // }
  };
  // $(document).mousemove(function (event) {
  //   hideTip(event);
  // });
  return (
      <div>
        <div className="info-container d-none d-md-block">
        <span className="dot info mb-4 info-container" onMouseOver={showTip} onMouseLeave={hideTip}>
         <svg id="Component_175_1" data-name="Component 175 – 1" xmlns="http://www.w3.org/2000/svg" width="42.651" height="39.89" viewBox="0 0 42.651 39.89">
          <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="21.03" cy="19.649" rx="21.03" ry="19.649" opacity="0.669"/>
          <path id="Path_2903" data-name="Path 2903" d="M21.326,0C33.1,0,42.651,8.93,42.651,19.945S33.1,39.89,21.326,39.89,0,30.96,0,19.945,9.548,0,21.326,0Z" fill="#fff" opacity="0.28"/>
          <path id="Path_2904" data-name="Path 2904" d="M15.3,26.364a2.152,2.152,0,0,1-4.3,0V15.606a2.152,2.152,0,0,1,4.3,0ZM13.152,11.3A2.152,2.152,0,1,1,15.3,9.152,2.152,2.152,0,0,1,13.152,11.3Z" transform="translate(8.104 2.053)" fill="#fff"/>
        </svg>

        </span>

          {active && (
              <div
                  className="info-tab info-containerd"
                  onMouseLeave={(e) => hideTip(e)}
              >
                <div
                    className="company-title bg-white d-block info-containerd"
                    style={{ color: '#9C9C9C' }}
                >
                  <h1
                      className="info-title info-containerd"
                      style={{ color: '#000' }}
                  >
                    About Research Earth
                  </h1>
                  <p className="info-containerd">
                      Research Earth is a live e-map of research institutions involved in research collaborations between Switzerland and low- and middle-income countries. The map enables you to explore where and with whom Swiss research institutions are working together in ongoing or recently completed research partnership projects.
                  </p>



                  <h1
                      className="info-title info-containerd"
                      style={{ color: '#000' }}
                  >
                    How to Use Research Earth
                  </h1>
                  <p className="info-containerd">
                    Explore Research Earth by{' '}
                    <strong style={{ color: '#000' }} className="info-containerd">
                      navigation the globe.
                    </strong>{' '}
                        Click on the dots to learn more about a research institution, its projects, network and disciplines.
                  </p>
                  <p className="info-containerd">
                      Use the search field with your own keywords to find relevant institutions or brows institutions working in specific disciplines or projects that are supported by a specific funder.
                  </p>

                  <div className="info-containerd" style={{ color: '#9C9C9C' }}>
                    <p className="mb-0 info-containerd">
                      For any enquiries and comments.
                    </p>
                    <p className="mb-0 info-containerd">Please contact us: </p>
                    <p><a href="mailto:info@research-earth.ch">info@research-earth.ch</a></p>
                  </div>

                </div>
              </div>
          )}
        </div>
      </div>
  );
}
