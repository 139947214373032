import React, { useContext } from "react";
import {
	getSelectedOrganizationProjectUrl,
	GET_METHOD,
	SET_ACTIVE_MARKER,
	SET_BROWSE_DESCIPLINES,
	SET_BROWSE_DISPLAY,
	SET_BROWSE_FUNDERS,
	SET_DESCIPLINES,
	SET_DESCIPLINE_DETAIL,
	SET_FUNDERS,
	SET_FUNDERS_DETAILS,
	SET_FUNDER_PROJECT_DETAIL,
	SET_SEARCH_DISPLAY,
	SET_SELECTED_DESCIPLINE,
	SET_SELECTED_FUNDER,
	SET_SELECTED_FUNDER_PROJECT,
	SET_SELECTED_ORGANIZATION,
} from "../constants/data";
import Context from "../context/store";
import {
	getAllDesciplines,
	getAllFunders,
	getDesciplineById,
	getFunderById,
	getFunderProjectById,
	getOrganizationById,
} from "../services/utils";
import "./Search.css";
export default function Browse(props) {
	const [state, dispatch] = useContext(Context);

	const handleDesciplinesClick = async () => {
		if (!state.isBrowserDesciplines) {
			dispatch({
				type: SET_BROWSE_DESCIPLINES,
				payload: !state.isBrowserDesciplines,
			});
		}
		if (!state.displayBrowse) {
			dispatch({ type: SET_BROWSE_DISPLAY, payload: !state.displayBrowse });
		}
		if (state.isBrowseFunders) {
			dispatch({ type: SET_BROWSE_FUNDERS, payload: false });
		}
		if (state.isFundersDetails) {
			dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		}
		if (state.isFundersProjectDetails) {
			dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		}
		if (Object.keys(state.selectedFunder).length > 0) {
			dispatch({ type: SET_SELECTED_FUNDER, payload: {} });
		}
		if (Object.keys(state.selectedFunderProject).length > 0) {
			dispatch({ type: SET_SELECTED_FUNDER_PROJECT, payload: {} });
		}
		if (state.isDesciplineDetail) {
			dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		}
		if (Object.keys(state.selectedDescipline).length > 0) {
			dispatch({ type: SET_SELECTED_DESCIPLINE, payload: {} });
		}

		let data = await getAllDesciplines();
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				dispatch({ type: SET_DESCIPLINES, payload: data.payload });
			} else {
				console.log(data.payload);
			}
		}
	};
	const Spinner = () => (
		<div className="desciplines-loader">
			<svg
				width="80"
				height="80"
				viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid"
			>
				<circle
					cx="50"
					cy="50"
					fill="none"
					stroke="#49d1e0"
					strokeWidth="10"
					r="35"
					strokeDasharray="164.93361431346415 56.97787143782138"
					transform="rotate(275.845 50 50)"
				>
					<animateTransform
						attributeName="transform"
						type="rotate"
						calcMode="linear"
						values="0 50 50;360 50 50"
						keyTimes="0;1"
						dur="1s"
						begin="0s"
						repeatCount="indefinite"
					/>
				</circle>
			</svg>
		</div>
	);
	const handleFundersClick = async () => {
		// debugger;
		dispatch({ type: SET_BROWSE_DESCIPLINES, payload: false });
		dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		dispatch({ type: SET_BROWSE_FUNDERS, payload: true });
		if (!state.displayBrowse) {
			dispatch({ type: SET_BROWSE_DISPLAY, payload: !state.displayBrowse });
		}
		if (Object.keys(state.selectedDescipline).length > 0) {
			dispatch({ type: SET_SELECTED_DESCIPLINE, payload: {} });
		}
		if (state.isFundersProjectDetails) {
			dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		}
		if (state.isFundersDetails) {
			dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		}
		if (Object.keys(state.selectedFunder).length > 0) {
			dispatch({ type: SET_SELECTED_FUNDER, payload: {} });
		}
		if (Object.keys(state.selectedFunderProject).length > 0) {
			dispatch({ type: SET_SELECTED_FUNDER_PROJECT, payload: {} });
		}
		let data = await getAllFunders();
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				dispatch({ type: SET_FUNDERS, payload: data.payload });
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleDesiplineClickById = async (e) => {
		dispatch({
			type: SET_BROWSE_DESCIPLINES,
			payload: false,
		});
		dispatch({ type: SET_DESCIPLINE_DETAIL, payload: true });
		let id = e;
		let data = await getDesciplineById(id);
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				dispatch({ type: SET_SELECTED_DESCIPLINE, payload: data.payload[0] });
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleOrganizationClickById = async (e) => {
		dispatch({ type: SET_ACTIVE_MARKER, payload: false });
		if (state.displayBrowse) {
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		}
		if (state.isBrowserDesciplines) {
			dispatch({ type: SET_BROWSE_DESCIPLINES, payload: false });
		}
		if (state.isDesciplineDetail) {
			dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		}
		if (state.isBrowseFunders) {
			dispatch({ type: SET_BROWSE_FUNDERS, payload: false });
		}
		if (state.isFundersDetails) {
			dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		}
		if (state.isFundersProjectDetails) {
			dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		}
		props.setActiveMarker(false);
		props.setIsCameraFlyDone(false);
		props.cb(e, "outside");

		dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
		dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		// getSelectedOrganization(e.target.id);
	};
	const getSelectedOrganization = async (id) => {
		props.cb(id, "outside");
		props.setDefaultZoom();
		dispatch({ type: SET_ACTIVE_MARKER, payload: false });
		if (state.displayBrowse) {
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		}
		if (state.isBrowserDesciplines) {
			dispatch({ type: SET_BROWSE_DESCIPLINES, payload: false });
		}
		if (state.isDesciplineDetail) {
			dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		}
		if (state.isBrowseFunders) {
			dispatch({ type: SET_BROWSE_FUNDERS, payload: false });
		}
		if (state.isFundersDetails) {
			dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		}
		if (state.isFundersProjectDetails) {
			dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		}
		let data = await getOrganizationById(id);
		if (data && Object.keys(data).length > 0) {
			if (data.isSuccess) {
				let organization = data.payload;
				organization.tab_projects.forEach((elem) => {
					fetch(getSelectedOrganizationProjectUrl(elem.project_id), {
						method: GET_METHOD,
					})
						.then(function (response) {
							return response.json();
						})
						.then(function (myJson) {
							organization.tab_projects.filter((el) => {
								myJson.forEach((e) => {
									if (parseInt(e.projects_id) === parseInt(el.project_id)) {
										el.details = e;
									}
								});
							});
							dispatch({
								type: SET_SELECTED_ORGANIZATION,
								payload: organization,
							});
						})
						.catch((error) => {
							console.log("error in getting organization".error);
						});
				});
				dispatch({ type: SET_SELECTED_ORGANIZATION, payload: data.payload });
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleFundersProjectOrganizationBack = () => {
		dispatch({ type: SET_SELECTED_FUNDER_PROJECT, payload: {} });
		dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		dispatch({ type: SET_FUNDERS_DETAILS, payload: true });
	};
	const handleDesciplineDetailBack = () => {
		dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		dispatch({ type: SET_SELECTED_DESCIPLINE, payload: {} });
		dispatch({
			type: SET_BROWSE_DESCIPLINES,
			payload: true,
		});
	};
	const handleFunderDetailsBack = () => {
		dispatch({ type: SET_SELECTED_FUNDER, payload: {} });
		dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		dispatch({ type: SET_BROWSE_FUNDERS, payload: true });
	};
	const handleFunderDetailsClick = async (e) => {

		let id = e.target.id;
		dispatch({ type: SET_BROWSE_FUNDERS, payload: false });
		dispatch({ type: SET_FUNDERS_DETAILS, payload: true });
		let data = await getFunderById(id);
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				dispatch({ type: SET_SELECTED_FUNDER, payload: data.payload[0] });
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleFundersProjectClick = async (e) => {
		let id = e.target.id;
		dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: true });
		let data = await getFunderProjectById(id);
		if (data && data?.payload?.length > 0) {
			if (data.isSuccess) {
				dispatch({
					type: SET_SELECTED_FUNDER_PROJECT,
					payload: data.payload[0],
				});
			} else {
				console.log(data.payload);
			}
		}
	};
	const handleFundersProjectOrganizationClick = (e) => {
		debugger;
		props.setActiveMarker(false);
		// getSelectedOrganization(e.target.id);
		// debugger;
		dispatch({ type: SET_ACTIVE_MARKER, payload: false });
		if (state.displayBrowse) {
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		}
		if (state.isBrowserDesciplines) {
			dispatch({ type: SET_BROWSE_DESCIPLINES, payload: false });
		}
		if (state.isDesciplineDetail) {
			dispatch({ type: SET_DESCIPLINE_DETAIL, payload: false });
		}
		if (state.isBrowseFunders) {
			dispatch({ type: SET_BROWSE_FUNDERS, payload: false });
		}
		if (state.isFundersDetails) {
			dispatch({ type: SET_FUNDERS_DETAILS, payload: false });
		}
		if (state.isFundersProjectDetails) {
			dispatch({ type: SET_FUNDER_PROJECT_DETAIL, payload: false });
		}
		props.setActiveMarker(false);
		props.setIsCameraFlyDone(false);
		props.cb(e.target.id, "outside");
		if (state.displayBrowse) {
			dispatch({ type: SET_SEARCH_DISPLAY, payload: false });
			dispatch({ type: SET_BROWSE_DISPLAY, payload: false });
		}
	};
	const handleBrowsClose = () => {
		dispatch({
			type: SET_BROWSE_DESCIPLINES,
			payload: false,
		});
		dispatch({
			type: SET_BROWSE_FUNDERS,
			payload: false,
		});
		dispatch({
			type: SET_BROWSE_DISPLAY,
			payload: false,
		});
	};
	return (
		<>
			<div className={`browse-form ${state.displayBrowse ? "show" : ""}`}>
				<div class="input-grup">
					<input
						disabled
						type="text"
						placeholder="Browse"
						class="form-control"
						name=""
					/>
					<ul class="d-flex icons-search font-14">
						<li class="" className={`justify-content-center align-self-center ${state.isBrowserDesciplines || state.isDesciplineDetail ? "selected-browse " : ""}`}>
							<a onClick={handleDesciplinesClick}>Disciplines</a>
						</li>
						{/*<span className="border-between"></span>*/}
						<li   className={`justify-content-center align-self-center ${state.isBrowseFunders || state.isFundersDetails || state.isFundersDetails ? "selected-browse " : ""}`}>
							<a onClick={handleFundersClick}>Funder</a>
						</li>
					</ul>
				</div>
				{state.displayBrowse && (
					<div
						className={`search-dropdown ${state.displayBrowse ? "show" : ""}`}
					>
						{state.isBrowserDesciplines && (
							<>
								<strong>Disciplines</strong>
								<ul>
									{state.desciplines.length
										? state.desciplines.map((elem) => (
											<li>
													<span
														id={elem.id}
														onClick={(e) => handleDesiplineClickById(elem.id)}
													>
														<p id={elem.id} className="desciplines-title">{elem.title.replace(/[^a-zA-Z ]/g, "")}</p>
														<i
															id={elem.id}
															class="fal fa-chevron-circle-right"
														></i>
													</span>
											</li>
										))
										: Spinner()}
								</ul>
								<hr className="desciplines-details-hr" />
								<hr
									className="close-desciplines-tab"
									onClick={(e) => handleBrowsClose(e)}
								/>
							</>
						)}
						{state.isDesciplineDetail && (
							<>
								<strong>{state.selectedDescipline.title}</strong>
								<ul>
									{Object.keys(state.selectedDescipline).length > 0 &&
									state.selectedDescipline?.discipline_organizations?.length
										? state.selectedDescipline?.discipline_organizations?.map(
											(elem) => (
												<li>
														<span
															id={elem.organization_id}
															onClick={(e) =>
																handleOrganizationClickById(
																	elem.organization_id
																)
															}
														>
															<p className="desciplines-title">
																{elem.organization_title.replace(/[^a-zA-Z ]/g, "")}
															</p>
															<i
																id={elem.organization_id}
																class="fal fa-globe"
															></i>
														</span>
												</li>
											)
										)
										: Spinner()}
								</ul>
								<hr className="desciplines-details-hr" />
								<span
									style={{ color: "white", cursor: "pointer" }}
									onClick={handleDesciplineDetailBack}
								>
									<i
										class="fal fa-chevron-circle-left"
										style={{ paddingRight: "5px" }}
									></i>
									Back
								</span>
							</>
						)}
						{state.isBrowseFunders && (
							<>
								<strong>Funders</strong>
								<ul>
									{state.funders.length
										? state.funders.map((elem) => (
											<li>
													<span
														id={elem.id}
														onClick={(e) => handleFunderDetailsClick(e)}
													>
														<p id={elem.id} className="desciplines-title">
															{elem.title.replace(/[^a-zA-Z ]/g, "")}{" "}
														</p>
														<i
															id={elem.id}
															class="fal fa-chevron-circle-right"
														></i>
													</span>
											</li>
										))
										: Spinner()}
								</ul>
								<hr className="desciplines-details-hr" />
								<hr
									className="close-desciplines-tab"
									onClick={(e) => handleBrowsClose(e)}
								/>
							</>
						)}
						{state.isFundersDetails && (
							<>
								<strong>Projects {state.selectedFunder.title}</strong>
								<ul>
									{state.selectedFunder?.funders_projects?.length
										? state.selectedFunder?.funders_projects?.map((elem) => (
											<li>
													<span
														id={elem.projects_id}
														onClick={(e) => handleFundersProjectClick(e)}
													>
														<p id={elem.projects_id} className="desciplines-title">{elem.projects_title.replace(/[^a-zA-Z ]/g, "")}{" "}</p>
														<i
															id={elem.projects_id}
															class="fal fa-chevron-circle-right"
														></i>
													</span>
											</li>
										))
										: Spinner()}
								</ul>
								<hr className="desciplines-details-hr" />
								<span
									style={{ color: "white", cursor: "pointer" }}
									onClick={handleFunderDetailsBack}
								>
									<i
										class="fal fa-chevron-circle-left"
										style={{ paddingRight: "5px" }}
									></i>
									Back
								</span>
							</>
						)}
						{state.isFundersProjectDetails && (
							<>
								<strong>Projects {state.selectedFunder.title}</strong>
								<ul>
									{state.selectedFunderProject?.partner_organizations?.length
										? state.selectedFunderProject?.partner_organizations?.map(
											(elem) => (
												<li>
														<span
															id={elem.organization_id}
															onClick={(e) =>
																handleFundersProjectOrganizationClick(e)
															}
														>
															<p id={elem.organization_id} className="desciplines-title">
															{elem.title.replace(/[^a-zA-Z ]/g, "")}{" "}
															</p>


															<i
																id={elem.organization_id}
																class="fal fa-globe"
															></i>
														</span>
												</li>
											)
										)
										: Spinner()}
								</ul>
								<hr className="desciplines-details-hr" />
								<span
									style={{ color: "white", cursor: "pointer" }}
									onClick={handleFundersProjectOrganizationBack}
								>
									<i
										class="fal fa-chevron-circle-left"
										style={{ paddingRight: "5px" }}
									></i>
									Back
								</span>
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
}
